<template>
  <a 
    class="sub-menu bg-hover px-5 py-3 text-sm text-black font-medium cursor-pointer hover:text-gray-700"
    :class="activeSubMenu ? 'color-primary' : 'text-black'"
    :href="route" target="_blank" >
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'TheSidebarSubMenuItem',
  props: {
    text: {
      type: String,
      required: true
    },
    route: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeSubMenu: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name == this.route?.name || val.path == this.route?.path) {
          this.$parent.setActiveMenu(true)
          this.activeSubMenu = true
        } else {
          this.activeSubMenu = false
        }
      }
    }
  },
  methods: {
    onClick() {
      if (this.route) this.$router.push(this.route)
      else console.log('No action found!')
    }
  }
}
</script>
<style>
.bg-hover:hover{
  background: #DFDFDF66;
}
</style>