import { createApp } from 'vue'
import App from './App.vue'
import router from './router'; // Import the Vue Router instance
import axios from 'axios';
import VueCookies from 'vue-cookies';
import './index.css'

const app = createApp(App);

app.config.globalProperties.$axios = axios;

app.use(router); // Use the Vue Router instance

app.use(VueCookies);

app.component("v-dropdown", require("@/components/v-dropdown.vue"));

app.mount('#app');

axios.defaults.withCredentials = true;