<template>
    <div class="bg-white hidden">
        <p class="text-center py-4 text-xs"><span class="color-secondary">Gym Management </span>© 2021</p>
    </div>

    <!-- Sidebar icons -->
    <tw-icon name="heroicons-outline:home" class="hidden"/>
    <tw-icon name="heroicons-outline:view-list" class="hidden"/>
    <tw-icon name="heroicons-outline:pencil-alt" class="hidden"/>
    <tw-icon name="heroicons-outline:chart-square-bar" class="hidden"/>
    <tw-icon name="heroicons-outline:cog" class="hidden"/>
    
</template>

<script>
export default {
}
</script>