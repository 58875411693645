<template>
  <nav class="bg-gradi border-gray-200 dark:bg-gray-900">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
    <a href="" class="flex items-center space-x-3 rtl:space-x-reverse">
        <span class="self-center text-2xl font-medium whitespace-nowrap text-white">Dashboard</span>
    </a>
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div class="relative w-full hidden md:block md:w-auto">
        <div class="absolute inset-y-0 text-white left-1.5 start-0 flex items-center ps-3 pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-500 dark:text-gray-400">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>

        </div>
        <input type="text" id="simple-search" class="bg-transparent border border-[#E7EAEE] text-[#E7EAEE] text-xs rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 py-2.5 pl-7 pr-28 placeholder-[#E7EAEE] dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search For All You Need..." required>
    </div>
    <div class="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg bg-transparent md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
          <div
  >
    <div class="relative text-lg">
      <button
        class="flex items-center justify-between w-full rounded-lg"
        @click="isOptionsExpanded = !isOptionsExpanded"
      >
        <!-- <span>{{ selectedOption }}</span> -->
        <img class="w-6 h-6" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1702978013/Icon_wuxr29.png" />
      </button>
      <transition
        enter-active-class="transform transition duration-500 ease-custom"
        enter-class="-translate-y-1/2 scale-y-0 opacity-0"
        enter-to-class="translate-y-0 scale-y-100 opacity-100"
        leave-active-class="transform transition duration-300 ease-custom"
        leave-class="translate-y-0 scale-y-100 opacity-100"
        leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
      >
        <ul
          v-show="isOptionsExpanded"
          class="absolute left-0 right-0 mb-4 w-max bg-white divide-y rounded-lg shadow-lg"
        >
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/inventory/items/create"> Inventory Item </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/inventory/items/create"> Inventory Group </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/inventory/item-groups/create"> Inventory Item </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/inventory/bundles/create"> Inventory Bundle </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/inventory/items/create"> Customer </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/sales/invoices/create"> Tax Invoice </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/sales/sales-orders/create"> Sales Order </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/purchases/vendors/create"> Vendor </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://acc.stage.silkysystems.com/1/purchases/bills/create"> Purchase Bill </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://crm.silkysystems.com/account/leads/create"> Lead </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://crm.silkysystems.com/account/tickets/create"> Ticket </a>
          </li>
          <li >
            <a class="px-3 d-inherit py-2 text-sm transition-colors duration-300 hover:bg-gray-200" target="_blank" href="https://crm.silkysystems.com/account/tasks/create"> Task </a>
          </li>
        </ul>
      </transition>
    </div>
  </div>
        </li>
        <li>
          <!-- <a href="#" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Home</a> -->
          <a href="#">
            <img class="w-6 h-6" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700563703/calendar_ltwef3.png" />
          </a>
        </li>
        <li>
          <a href="#">
            <img class="w-6 h-6" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700563703/to-do-list_1_zi9zj8.png" />
          </a>
        </li>
        <li>
          <a href="#">
            <img class="w-6 h-6" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700563703/Layer_1_b7x7r9.png" />
          </a>
        </li>
        <li>
          <button type="button" class="relative inline-flex items-center text-sm font-medium text-center text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <img class="w-5 h-5" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700563703/Icon_2_fjbtbn.png" />
            <span class="sr-only">Notifications</span>
              <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full -top-4 -right-2 dark:border-gray-900">20</div>
            </button>
        </li>
        <li>
          <a href="#">
            <img class="w-6 h-6" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1702978139/Icon_2_dvh764.webp" />
          </a>
        </li>
        <li>
          <a href="#">
            <img class="w-6 h-6" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1702978013/Avatar_qlp48s.webp" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
        <div class="flex w-99 justify-between mb-3 mt-3">
            <div class="flex items-start p-4 rounded-lg bg-white">
                <div class="pr-14 flex flex-col gap-2">
                  <span class="pl-2 text-blue-800 text-xl font-bold">240</span>
                  <span class="pl-2 text-sm">total</span>
                </div>
                <div class="flex items-center pt-2">
                    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1695625882/people_dcsgij.png" alt="">
                </div>
            </div>

            <div class="flex items-start p-4 rounded-lg bg-white">
                <div class="pr-14 flex flex-col gap-2">
                  <span class="pl-2 text-blue-800 text-xl font-bold">240</span>
                  <span class="pl-2 text-sm">app</span>
                </div>
                <div class="flex items-center pt-2">
                    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1695625882/people_dcsgij.png" alt="">
                </div>
            </div>

            <div class="flex items-start p-4 rounded-lg bg-white">
                <div class="pr-14 flex flex-col gap-2">
                  <span class="pl-2 text-blue-800 text-xl font-bold">240</span>
                  <span class="pl-2 text-sm">app</span>
                </div>
                <div class="flex items-center pt-2">
                    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1695625882/people_dcsgij.png" alt="">
                </div>
            </div>

            <div class="flex items-start p-4 rounded-lg bg-white">
                <div class="pr-14 flex flex-col gap-2">
                  <span class="pl-2 text-blue-800 text-xl font-bold">240</span>
                  <span class="pl-2 text-sm">mod</span>
                </div>
                <div class="flex items-center pt-2">
                    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1695625882/people_dcsgij.png" alt="">
                </div>
            </div>

            <div class="flex items-start p-4 rounded-lg bg-white">
                <div class="pr-14 flex flex-col gap-2">
                  <span class="pl-2 text-blue-800 text-xl font-bold">240</span>
                  <span class="pl-2 text-sm">Neww</span>
                </div>
                <div class="flex items-center pt-2">
                    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1695625882/people_dcsgij.png" alt="">
                </div>
            </div>

        </div>
        <!-- <div>
  <apexchart width="500" type="bar" 
  :options="options" 
  :series="series"></apexchart>
</div> -->
<!-- <div>
  Dashboard
</div> -->
</template>
<style>

.bg-gradi {
  background: linear-gradient(90.27deg, rgba(204, 218, 227, 0.4) 0%, rgba(8, 104, 167, 0) 100%);
  border-radius: 20px;
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #909;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #909;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #909;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #909;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #909;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #909;
}

.w-99 {
  width: 99%;
}
.d-inherit {
  display: inherit;
}
.ease-custom {
  transition-timing-function: cubic-bezier(.61,-0.53,.43,1.43);
}
</style>
<script>
export default {
  data() {
    return {
      isOptionsExpanded: false,
      selectedOption: "Inventory Item",
      options: ["Inventory Item", "2x", "3x", "4x or more"],
      links: ["https://acc.stage.silkysystems.com/1/inventory/items/create", "2x", "3x", "4x or more"]
    };
  },
  methods: {
    
  }
};
</script>
