<template>
    <div>
      <!-- <h2>Login</h2> -->
      <!-- <form @submit.prevent="login">
        <label for="email">Email:</label>
        <input v-model="email" type="email" id="email" required />
  
        <label for="password">Password:</label>
        <input v-model="password" type="password" id="password" required />
  
        <button type="submit">Login</button>
      </form> -->
      <form @submit.prevent="login" class="form" id="kt_login_signin_form">
        <div class="container-fluid p-0 m-0" style="background: #F9F9F9;">
            <div class="flex justify-center items-center h-screen w-full">
                <div class="col-12 flex flex-column h-screen">
                    <div class="flex items-center justify-center h-screen">
                        <div class="d-flex align-items-center h-auto">
                            <div class="container-fluid pr-20 pl-20 pt-16 pb-16 m-0" style="box-shadow: 0px 0px 10px 0px #00000040; background: #F9F9F9;" >
                                <div class="flex flex-col justify-center w-100">
                                    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1702812742/Group_cqqq8a.webp" alt="Logo" 
                                        class="mb-4 self-center" 
                                        />
                                        <h2 class="text-3xl text-center text-[#021DA5] font-bold">  
                                            Silky Systems
                                          </h2>

                                        <div class="mb-4 mt-3">
                                          <span style="letter-spacing: -0.5px;" class="text-2xl text-[#282828] font-semibold">  
                                            Sign in
                                          </span>
                                        </div>
                                </div>

                                <span class="text-red-400 text-center block">{{ errorsApi }}</span>

                                <div class="form-group mt-3">
                                    <span> Email <span class="text-red-400">*</span></span>
                                    <input class="form-control outline-none height-42 w-[350px] py-5" v-model="email" type="email" placeholder="eg. email@gmail.com" name="email" id="email" autofocus autocomplete="off" />
                                    <!-- <br/><span class="text-sm text-red-400" v-if="v$.user.email.$error"> {{ v$.user.email.$errors[0].$message }}</span> -->
                                  </div>

                                <div class="form-group mt-3">
                                  <span> Password <span class="text-red-400">*</span></span>
                                    <input class="form-control outline-none height-42 inline-block w-[350px]" v-model="password" type="password" placeholder="*********" autocomplete="off" name="password" id="password" />
                                    <!-- <br/><span class="text-sm text-red-400" v-if="v$.user.password.$error"> {{ v$.user.password.$errors[0].$message }}</span> -->
                                  </div>

                                <!-- <div class="form-group flex justify-center mb-6">
                                    <div class="checkbox-inline justify-center ml-14">
                                        <label class="checkbox mr-0 ml-1 flex remember_me">
                                            <input type="checkbox" name="remember"/>
                                            <span class="m-0"></span>
                                        </label>
                                        <span class="text-lg">تذكرني ؟</span>
                                    </div>
                                </div> -->
                                <div class="form-group mt-4 text-center">
                                    <button type="submit" class="btn bg_sec rounded text-white btn-primary-login height-42 btn-block text-sm font-normal py-3 mb-3"> Login </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  axios.defaults.withCredentials = true;

  export default {
    data() {
      return {
        email: '',
        password: '', //123123
      };
    },
    methods: {
      login() {
        // Call your login API endpoint here
        // Use Axios or another HTTP client library
        // For simplicity, let's assume an imaginary API endpoint
  
        const loginData = {
          email: this.email,
          password: this.password,
        };
  
        axios.post('https://erp.sandbox.silkysystems.com/api/login', loginData, {
            withCredentials: true // this tells Axios to send cookies along with the request
          })
            .then(response => {
            // Handle successful login
            const crmCookies = response.data.crm['Set-Cookie'];
            crmCookies.forEach(crmCookie => {
              // Split the string based on ';'
              const crmCookieAttributes = crmCookie.split(';');

              // Further split based on '=' to get the name and value
              const [crmCookieName, crmCookieValue] = crmCookieAttributes[0].split('=');

              console.log(`${crmCookieName}=${crmCookieValue}`);

              // ; domain=6bba-102-186-91-145.ngrok-free.app
              // Set the cookie
              // Cookies.set(`${crmCookieName}`, `${crmCookieValue}`, { expires: 60, path: '/',  secure: false, sameSite: 'Lax', httpOnly});
              document.cookie = `${crmCookieName}=${crmCookieValue}; domain=.silkysystems.com; path=/`;
            });

            const accCookies = response.data.acc['Set-Cookie'];
            accCookies.forEach(accCookie => {
              // Split the string based on ';'
              const accCookieAttributes = accCookie.split(';');

              // Further split based on '=' to get the name and value
              const [accCookieName, accCookieValue] = accCookieAttributes[0].split('=');

              // ; domain=6bba-102-186-91-145.ngrok-free.app
              // Set the cookie
              // Cookies.set(`${accCookieName}`, `${accCookieValue}`, { expires: 60, path: '/',  secure: false, sameSite: 'Lax', httpOnly});
              document.cookie = `${accCookieName}=${accCookieValue}; domain=.silkysystems.com; path=/`;
            });

            

            // Dummy response
            const login_response = {
              default_url: "/dashboard",
              token: "524|Th4ppK4hgKSz77fxHybUx7oGsw0uI5qxFKf9ulkz",
            }
              localStorage.setItem("userInfo", JSON.stringify(login_response));
              localStorage.setItem("responselogin", JSON.stringify(login_response));
              window.location.reload()
          }).catch(function (error) {
      if (error){
        console.log(error)
      }
      });
      },
    },
  };
  </script>
  
  <style scoped>
.form-group {
    margin-bottom: 0.5rem;
}
.form-control {
    display: block;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #fdfdfd;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn {
    outline: none !important;
    vertical-align: middle;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn:hover:not(.btn-text), .btn:focus:not(.btn-text), .btn.focus {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.btn-block {
    display: block;
    width: 100%;
}
.bg_sec {
  background-color: #021DA5;
}
.hover-bg:hover {
  background: #021DA50D !important;
  cursor: pointer;
}
  </style>
  