<template>
    <div>
      <!-- <h2>Login</h2> -->
      <!-- <form @submit.prevent="login">
        <label for="email">Email:</label>
        <input v-model="email" type="email" id="email" required />
  
        <label for="password">Password:</label>
        <input v-model="password" type="password" id="password" required />
  
        <button type="submit">Login</button>
      </form> -->
      <form @submit.prevent="login" class="form" id="kt_login_signin_form">
        <div class="container-fluid bg-white p-0 m-0">
            <div class="row m-0 h-screen w-100">
                <div dir="rtl" class="col-12 d-flex flex-column h-screen">
                    <div class="d-flex justify-content-center h-screen">
                        <div class="d-flex align-items-center h-auto" style="max-width: 380px;">
                            <div class="container-fluid p-0 m-0">
                                <div class="flex flex-col justify-center text-center w-100">
                                    <img src="https://res.cloudinary.com/dlh4c743j/image/upload/v1692533967/ss-logo_tpetsd.webp" alt="Logo" 
                                        class="mb-4 self-center"
                                        style="width: 144px;" 
                                        />

                                        <div class="mb-6">
                                          <span class="text-xl font-medium">قم بتسجيل الدخول إلى لوحة تحكم Silky Systems</span>
                                        </div>
                                </div>

                                <!-- <span class="text-red-400 text-center block">{{ errorsApi }}</span> -->

                                <div class="form-group mt-3">
                                    <span>البريد الألكتروني <span class="text-red-400">*</span></span>
                                    <input class="form-control height-42 py-5" v-model="email" type="email" placeholder="eg. email@gmail.com" name="email" id="email" autofocus autocomplete="off" />
                                    <!-- <br/><span class="text-sm text-red-400" v-if="v$.user.email.$error"> {{ v$.user.email.$errors[0].$message }}</span> -->
                                  </div>

                                <div class="form-group mt-3">
                                  <span>كلمة المرور <span class="text-red-400">*</span></span>
                                    <input class="form-control height-42" v-model="password" type="password" placeholder="*********" autocomplete="off" name="password" id="password" />
                                    <!-- <br/><span class="text-sm text-red-400" v-if="v$.user.password.$error"> {{ v$.user.password.$errors[0].$message }}</span> -->
                                  </div>

                                <!-- <div class="form-group flex justify-center mb-6">
                                    <div class="checkbox-inline justify-center ml-14">
                                        <label class="checkbox mr-0 ml-1 flex remember_me">
                                            <input type="checkbox" name="remember"/>
                                            <span class="m-0"></span>
                                        </label>
                                        <span class="text-lg">تذكرني ؟</span>
                                    </div>
                                </div> -->
                                <div class="form-group mt-3 text-center">
                                    <button type="submit" id="kt_login_signin_submit" class="btn bg_sec text-white btn-primary-login height-42 btn-block text-lg font-weight-bold py-4 mb-3">تسجيل الدخول</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center pb-4">
                        <div class="d-flex align-items-end">
                            <div class="text-center">
                                كل الحقوق محفوظة لدي <a href="https://silkysys.com" target="_blank"
                                    class="text-primary ml-1">Silky Systems</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  axios.defaults.withCredentials = true;

  export default {
    data() {
      return {
        email: '',
        password: '', //123123
      };
    },
    methods: {
      login() {
        // Call your login API endpoint here
        // Use Axios or another HTTP client library
        // For simplicity, let's assume an imaginary API endpoint
  
        const loginData = {
          email: this.email,
          password: this.password,
        };
  
        axios.post('https://erp.sandbox.silkysystems.com/api/login', loginData, {
            withCredentials: true // this tells Axios to send cookies along with the request
          })
            .then(response => {
            // Handle successful login
            const crmCookies = response.data.crm['Set-Cookie'];
            crmCookies.forEach(crmCookie => {
              // Split the string based on ';'
              const crmCookieAttributes = crmCookie.split(';');

              // Further split based on '=' to get the name and value
              const [crmCookieName, crmCookieValue] = crmCookieAttributes[0].split('=');

              console.log(`${crmCookieName}=${crmCookieValue}`);

              // ; domain=6bba-102-186-91-145.ngrok-free.app
              // Set the cookie
              // Cookies.set(`${crmCookieName}`, `${crmCookieValue}`, { expires: 60, path: '/',  secure: false, sameSite: 'Lax', httpOnly});
              document.cookie = `${crmCookieName}=${crmCookieValue}'`;
            });

            const accCookies = response.data.acc['Set-Cookie'];
            accCookies.forEach(accCookie => {
              // Split the string based on ';'
              const accCookieAttributes = accCookie.split(';');

              // Further split based on '=' to get the name and value
              const [accCookieName, accCookieValue] = accCookieAttributes[0].split('=');

              // ; domain=6bba-102-186-91-145.ngrok-free.app
              // Set the cookie
              // Cookies.set(`${accCookieName}`, `${accCookieValue}`, { expires: 60, path: '/',  secure: false, sameSite: 'Lax', httpOnly});
              document.cookie = `${accCookieName}=${accCookieValue}'`;
            });
          })
          .catch(error => {
            // Handle login error
            console.error(error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your styling here */
  /* @import "@/assets/material-icons.min.css";
@import "@/assets/side-menu.css"; */
/* @import "@/assets/style.bundle.css"; */

.bg_sec {
  background-color: #021DA5;
}
.hover-bg:hover {
  background: #021DA50D !important;
  cursor: pointer;
}
  </style>
  