import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../components/Login.vue';
import DashboardComponent from '../components/DashboardComponent.vue';
import routes from './routes'

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home,
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     component: Login,
//   },
//   {
//     path: '/dashboard',
//     component: DashboardComponent,
//     meta: { requiresAuth: true }
//   },

// ];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });

export default router;
