<template>
  <!-- Toggle sidebar button -->
  <button 
    class="fixed z-50 bottom-4 right-4 w-16 h-16 rounded-full bg-gray-900 lg:hidden"
    @click="toggleSidebar">
    <tw-icon 
      name="heroicons-solid:menu-alt-4" 
      class="btn-toggle w-6 h-6 text-white" 
      :class="{'opacity-0': showSidebar}" />
    <tw-icon 
      name="heroicons-solid:x" 
      class="btn-toggle w-6 h-6 text-white"
      :class="{'opacity-0': !showSidebar}" />
  </button>

  <!-- Sidebar Overlay -->
  <!-- <div 
    class="fixed inset-0 bg-black bg-opacity-25 z-30 lg:hidden" 
    :class="{'hidden': !showSidebar}" 
    @click="showSidebar = false" /> -->
    
    <!-- Header -->
    <div class="flex" :class="[isOpen == true ? 'w-96' : 'w-36']"></div>
    <div class="fixed bg-blue pr-2 flex flex-row border-gray-200 text-white z-10 min-h-0 top-0 bottom-0 overflow-hidden overflow-y-scroll"
    :class="[isOpen == true ? 'lg-w-19' : 'lg:w-1/12 ', {'hidden': allowSidebarHide && !showSidebar}]"
    >
        <div class="relative min-h-0"
        :class="[isOpen == true ? 'w-full ' : 'w-4/5 ']"
        >
        
        <header class="text-center py-2 px-5 duration-300" 
      :class="isOpen == true ? 'lg:w-64 ' : 'lg:w-20 '"
      >
      <!-- <svg v-on:click='isOpen = !isOpen' :class="{'hidden': showSidebar}" class="icon border
        cursor-pointer 
        w-9 h-9 z-10 bg-white
          text-black text-4xl rounded-full 
          absolute right-0 top-9
          group-hover:text-gray-900"  xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
<path d="M24 44C35.02 44 44 35.02 44 24C44 12.98 35.02 4 24 4C12.98 4 4 12.98 4 24C4 35.02 12.98 44 24 44ZM18.42 22.94L25.48 15.88C25.78 15.58 26.16 15.44 26.54 15.44C26.92 15.44 27.3 15.58 27.6 15.88C28.18 16.46 28.18 17.42 27.6 18L21.6 24L27.6 30C28.18 30.58 28.18 31.54 27.6 32.12C27.02 32.7 26.06 32.7 25.48 32.12L18.42 25.06C17.82 24.48 17.82 23.52 18.42 22.94Z" fill="#021DA5"/>
</svg> -->
<!-- <svg v-on:click='isOpen = !isOpen' :class="{'hidden': showSidebar}" class="icon border
        cursor-pointer 
        w-8 h-8 px-1 z-10 bg-blue-800
          text-white rounded-full 
          absolute -right-3 top-9
          group-hover:text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path v-if="isOpen" stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
  <path v-if="!isOpen" stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg> -->



      </header>
      <!-- Menu -->
      <div class="flex w-full justify-center items-center">
        <img class="w-40" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700405936/Logo_5_fhomrn.png" alt="">
      </div>
      <div class="mt-4 ml-4">

        <!-- <RouterLink v-if="leadsPermission && rolesNotClient && leadsModuleCheck" to="/leads" >
          <div 
    class="px-5 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-white rounded-lg': !activeMenu}]"
    >
    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700472929/Group_74_dioh6q.webp" class="w-6 h-6" />

    <div class="pl-2 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-pri font-semibold group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Dashboard' }}</span>
      </div>
    </div>
  </div>
</RouterLink> -->

<a
target="_blank" href="https://acc.stage.silkysystems.com/1" >
          <div 
    class="px-5 mt-3 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-white rounded-lg': !activeMenu}]"
    >
    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700487657/Group_74_1_n2jzfb.webp" class="w-6 h-6" />

    <div class="pl-2 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-pri font-semibold group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Dashboard' }}</span>
      </div>
    </div>
  </div>
</a>

<a
target="_blank" href="https://acc.stage.silkysystems.com/1/marketplace" >
          <div 
    class="px-5 mt-3 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-white rounded-lg': !activeMenu}]"
    >
    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700487657/Group_74_1_n2jzfb.webp" class="w-6 h-6" />

    <div class="pl-2 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-[#305060] font-semibold group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Market Place' }}</span>
      </div>
    </div>
  </div>
</a>




        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700477953/Group_76_tai0rb.png" 
          activeColor="#18745C"
          activeBgColor="#C1E9DE"
          :text="isOpen == false ? '' : 'Sales'" >
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Customers'"
                route="https://acc.stage.silkysystems.com/1/sales/customers">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Invoices'"
                route="https://acc.stage.silkysystems.com/1/sales/invoices">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Sales Order'" 
                route="https://acc.stage.silkysystems.com/1/sales/sales-orders">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Quotations'" 
                route="https://acc.stage.silkysystems.com/1/sales/estimates">
              </sub-menu-item>
              <sub-menu-item
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Credit Notes'" 
                route="https://acc.stage.silkysystems.com/1/credit-debit-notes/credit-notes">
              </sub-menu-item>
            </template>
        </menu-item>

        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485217/Group_76_1_kmfcnu.webp" 
          activeColor="#AF8934"
          activeBgColor="#FFF9CC"
          :text="isOpen == false ? '' : 'Purchase'" 
          route="/data">
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Vendors'" 
                route="https://acc.stage.silkysystems.com/1/purchases/vendors">
              </sub-menu-item>
              <sub-menu-item v-if="projectsPermission"
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Bills'" 
                route="https://acc.stage.silkysystems.com/1/purchases/bills">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Purchase Order'" 
                route="https://acc.stage.silkysystems.com/1/sales/purchase-orders">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Debit Notes'" 
                route="https://acc.stage.silkysystems.com/1/credit-debit-notes/debit-notes">
              </sub-menu-item>
          </template>
        </menu-item>

        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485218/Group_76_2_vmsldh.webp" 
          activeColor="#E19048"
          activeBgColor="#F9E9DA"
          :text="isOpen == false ? '' : 'Inventory'" 
          route="/data">
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Items'" 
                route="https://acc.stage.silkysystems.com/1/inventory/items">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Categories'" 
                route="https://acc.stage.silkysystems.com/1/settings/item/categories">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Group'" 
                route="https://acc.stage.silkysystems.com/1/inventory/item-groups">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Modifier'" 
                route="https://acc.stage.silkysystems.com/1/inventory/modifiers">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'modifer group'" 
                route="https://acc.stage.silkysystems.com/1/inventory/modifiers-group">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Bundles'" 
                route="https://acc.stage.silkysystems.com/1/inventory/bundles">
              </sub-menu-item>
          </template>
        </menu-item>
        
        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485212/Group_76_3_pwypof.webp" 
          activeColor="#484848"
          activeBgColor="#EAEAEA"
          :text="isOpen == false ? '' : 'Warehouses'" >
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Warehouses'" 
                route="https://acc.stage.silkysystems.com/1/inventory/warehouses">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Adjustment'" 
                route="https://acc.stage.silkysystems.com/1/inventory/adjustments">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'zones'" 
                route="https://acc.stage.silkysystems.com/1/warehouse/zones">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Shelves'" 
                route="https://acc.stage.silkysystems.com/1/warehouse/shelves">
              </sub-menu-item>
            </template>
        </menu-item>

        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485213/Group_76_4_zptldt.webp" 
          activeColor="#3A90BB"
          activeBgColor="#DCEBF2"
          :text="isOpen == false ? '' : 'Manufacturing'" >
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Composite Items'" 
                route="https://acc.stage.silkysystems.com/1/composite-items/composite-items">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Manufucturers'" 
                route="https://acc.stage.silkysystems.com/1/inventory/manufacturers">
              </sub-menu-item>
            </template>
        </menu-item>

        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485212/Group_76_5_i6orka.webp" 
          activeColor="#646B0C"
          activeBgColor="#F8F9E9"
          :text="isOpen == false ? '' : 'Banking'" >
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Accounts'" 
                route="https://acc.stage.silkysystems.com/1/banking/accounts">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'transferes'" 
                route="https://acc.stage.silkysystems.com/1/banking/transfers">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'attendance'" 
                route="https://acc.stage.silkysystems.com/1/banking/reconciliations">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Reconciliation'" 
                route="https://acc.stage.silkysystems.com/1/banking/reconciliations">
              </sub-menu-item>
            </template>
        </menu-item>

        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485212/Group_76_6_niusod.webp" 
          activeColor="#438C47"
          activeBgColor="#EAF7EB"
          :text="isOpen == false ? '' : 'Accounting'" >
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Chart of Account'" 
                route="https://acc.stage.silkysystems.com/1/double-entry/chart-of-accounts">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Cost Center'" 
                route="https://acc.stage.silkysystems.com/1/cost-centers/cost-centers">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Assets'" 
                route="https://acc.stage.silkysystems.com/1/assets/assets">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Transactions'" 
                route="https://acc.stage.silkysystems.com/1/banking/transactions">
              </sub-menu-item>
            </template>
        </menu-item>

        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485213/Group_76_7_jomt5r.webp" 
          activeColor="#101E6A"
          activeBgColor="#E9E9EE"
          :text="isOpen == false ? '' : 'POS'" >
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'POS'" 
                route="https://acc.stage.silkysystems.com/1/pos/pos">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'POS orders'" 
                route="https://acc.stage.silkysystems.com/1/pos/orders">
              </sub-menu-item>
            </template>
        </menu-item>

        <menu-item
          icon="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485213/Group_76_8_mhbfjf.webp" 
          activeColor="#685286"
          activeBgColor="#EEEBF2"
          :text="isOpen == false ? '' : 'HRMS'" >
          <template #sub-menu>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Employees'" 
                route="https://acc.stage.silkysystems.com/1/employees/employees">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Expensse Claims'" 
                route="https://acc.stage.silkysystems.com/1/expenses/expense-claims">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Leaves'" 
                route="https://crm.silkysystems.com/account/leaves">
              </sub-menu-item>
              <sub-menu-item 
                class="grid grid-cols-1"
                :text="isOpen == false ? '' : 'Attendence'" 
                route="https://crm.silkysystems.com/account/attendances">
              </sub-menu-item>
            </template>
        </menu-item>

        <a
          target="_blank" href="https://acc.stage.silkysystems.com/1/marketplace" >
          <div 
    class="px-5 mt-3 flex items-center py-3 cursor-pointer border border-[#C24C4C]"
    :class="[{'color-primary': activeMenu, 'group bg-white rounded-lg': !activeMenu}]"
    >
    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1702991698/Group_76_kvqhxs.webp" class="w-6 h-6" />

    <div class="pl-2 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-[#C24C4C] font-semibold group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Leads' }}</span>
      </div>
    </div>
  </div>
</a>

<a
          target="_blank" href="https://acc.stage.silkysystems.com/1/marketplace" >
          <div 
    class="px-5 mt-3 flex items-center py-3 cursor-pointer border border-[#653AAD]"
    :class="[{'color-primary': activeMenu, 'group bg-white rounded-lg': !activeMenu}]"
    >
    <img src="https://res.cloudinary.com/drcfigqqr/image/upload/v1702997493/Group_76_2_grftvd.webp" class="w-6 h-6" />

    <div class="pl-2 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-[#653AAD] font-semibold group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Contacts' }}</span>
      </div>
    </div>
  </div>
</a>

        <!-- <RouterLink v-if="clientsPermission && rolesNotClient && clientsModuleCheck" to="/clients" >
          <div 
    class="px-5 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-[#000] hover:bg-gray-50': !activeMenu}]"
    >
    <img class="w-6 h-6" src="https://res.cloudinary.com/drcfigqqr/image/upload/v1700485216/Group_76_9_bhul8j.webp" />

    <div class="pl-4 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-white font-medium group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Leads' }}</span>
      </div>
    </div>
  </div>
        </RouterLink>

        <RouterLink v-if="clientsPermission && rolesNotClient && clientsModuleCheck" to="/products" >
          <div 
    class="px-5 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-[#000] hover:bg-gray-50': !activeMenu}]"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
</svg>

    <div class="pl-4 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-white font-medium group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Contact Us' }}</span>
      </div>
    </div>
  </div>
        </RouterLink>

        <RouterLink v-if="clientsPermission && rolesNotClient && clientsModuleCheck" to="/orders" >
          <div 
    class="px-5 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-[#000] hover:bg-gray-50': !activeMenu}]"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
</svg>

    <div class="pl-4 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-white font-medium group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Ticket CS' }}</span>
      </div>
    </div>
  </div>
        </RouterLink>

        <RouterLink v-if="clientsPermission && rolesNotClient && clientsModuleCheck" to="/campaigns" >
          <div 
    class="px-5 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-[#000] hover:bg-gray-50': !activeMenu}]"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
</svg>

    <div class="pl-4 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-white font-medium group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Marketing Campaigns' }}</span>
      </div>
    </div>
  </div>
        </RouterLink>

        <RouterLink v-if="clientsPermission && rolesNotClient && clientsModuleCheck" to="/tickets" >
          <div 
    class="px-5 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-[#000] hover:bg-gray-50': !activeMenu}]"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
</svg>

    <div class="pl-4 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-white font-medium group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Doucments' }}</span>
      </div>
    </div>
  </div>
        </RouterLink>

        <RouterLink v-if="clientsPermission && rolesNotClient && clientsModuleCheck" to="/events" >
          <div 
    class="px-5 flex items-center py-3 cursor-pointer"
    :class="[{'color-primary': activeMenu, 'group bg-[#000] hover:bg-gray-50': !activeMenu}]"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
</svg>

    <div class="pl-4 flex-grow flex items-center justify-between">
      <div 
        class="text text-sm text-white font-medium group-hover:text-gray-800"
        :class="{'color-primary': activeMenu}">
        <span>{{ isOpen == false ? '' : 'Proposals' }}</span>
      </div>
    </div>
  </div>
        </RouterLink> -->
        

  <!-- <template #sub-menu>
                  <sub-menu-sub 
                    class="grid grid-cols-1"
                    text="sub_menu.title" 
                    route="/oi"
                  >
                </sub-menu-sub>
                <sub-menu-sub 
                    class="grid grid-cols-1"
                    text="sub_menu.title" 
                    route="/oi"
                  >
                </sub-menu-sub>
          </template> -->
        <!-- <template #sub-sub>
          <menu-item
          v-for="admin_menu in admin_menus"
          :key="admin_menu.id"
          :icon="admin_menu.icon_class" 
          :text="admin_menu.title" 
          :route="{path: admin_menu.link}">
            <template v-if=" admin_menu.sub_menus?.length > 0" #sub-menu>
              <sub-menu-sub 
                class="grid grid-cols-1"
                v-for="sub_menu in admin_menu.sub_menus"
                :key="sub_menu.id"
                :text="sub_menu.title" 
                :route="{path: sub_menu.link}"
              >
            </sub-menu-sub>
          </template>
          
        </menu-item>
          </template> -->
      </div>
        </div>
    </div>
   
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { useRoute } from 'vue-router'
import MenuHeader from './TheSidebarMenuHeader.vue'
import MenuItem from './TheSidebarMenuItem.vue'
import SubMenuHeader from './TheSidebarSubMenuHeader.vue'
import SubMenuItem from './TheSidebarSubMenuItem.vue'
import SubMenuSub from './TheSidebarSubMenuSub.vue'
import { mapActions } from 'vuex';
export default {
  name: 'TheSidebar',
  data() {
    return {
      subMenus: [
        { text: 'inventory', subItems: [ 
          {text: 'sales', route: 'https:google.com'},
          {text: 'sales', route: 'https:google.com'} 
        ] }
      ],
      isOpen: true,
    };
  },
  components: {
    MenuHeader,
    MenuItem,
    SubMenuHeader,
    SubMenuItem,
    SubMenuSub,
  },
  emits: ['visibility-change'],
  methods: {
    // ...mapActions(["logout"]),

    signout() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("redirection_url")
      localStorage.removeItem("token")
      window.location.href = "/";
      // this.logout({
      //   Token: JSON.parse(localStorage.getItem("userInfo")).token
      // });
    },
//     hasPermissions(permission_name) {
//     const admin_menus = JSON.parse(localStorage.getItem("responselogin")).data.permissions;
//     let group_rights = [];
//     admin_menus.forEach(group_right => {
//         group_rights.push(group_right)
//     });
//     let permissions_found = false;
//     const permissions = group_rights.find(right => right.permission_name == permission_name)
//     if(permissions) {
//         permissions_found = permissions.permission_type != "none" ? true : false
//     }
//     return permissions_found;
// },
// checkNotClient() {
//     const admin_menus = JSON.parse(localStorage.getItem("responselogin")).data.roles;
//     const permissions_found = (admin_menus.indexOf("client") == -1);
//     console.log(permissions_found)
//     return permissions_found;
// },
// leadsCheckInModules() {
//     const admin_menus = JSON.parse(localStorage.getItem("responselogin")).data.modules;
//     const permissions_found = (admin_menus.indexOf("leads") > -1);
//     console.log(permissions_found)
//     return permissions_found;
// },
// clientsCheckInModules() {
//     const admin_menus = JSON.parse(localStorage.getItem("responselogin")).data.modules;
//     const permissions_found = (admin_menus.indexOf("clients") > -1);
//     console.log(permissions_found)
//     return permissions_found;
// },
    setOpen() {
      this.open == false;
      console.log(open)
    },
  },
  setup(_, {emit}) {
    const showSidebar = ref(false)
    const toggleSidebar = () => showSidebar.value = !showSidebar.value
    watch(showSidebar, (val) => {
      emit('visibility-change', val)
    })

    const route = useRoute()
    watch(route, () => showSidebar.value = false)
   
    
    const widthBreakpoint = 1024 
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, (newWidth) => {
      if (newWidth < widthBreakpoint) showSidebar.value = false
    })

    const allowSidebarHide = computed(() => windowWidth.value < widthBreakpoint)

    const admin_menus = JSON.parse(localStorage.getItem("userInfo")).admin_menu
    const open = true
    // const setOpen = () => {
    //   !open
    //   console.log(open)
    // }

    watch(open)

    return {
      showSidebar,
      toggleSidebar,
      allowSidebarHide,
    }
  }
}
</script>

<style scoped>
.btn-toggle {
  @apply absolute left-1/2 top-1/2 transition duration-300 transform -translate-x-1/2 -translate-y-1/2;
}
.bg-blue {
  background: inherit;
}
.text-pri {
  color: #021DA5;
}
@media (min-width: 1024px) {
    .lg-w-19 {
        width: 18%;
    }
}
</style>