import authenticateUser from '../auth/authenticateUser'
import checkUserSession from '../auth/checkUserSession'
// Import En Login Routes
// import EN_Signin from '@/pages/auth/en/Signin.vue'
// import AR_Signin from '@/pages/auth/ar/Signin.vue'
import Login from '../components/Login.vue';
import LoginAr from '../components/LoginAr.vue';
import DashboardComponent from '../components/DashboardComponent.vue';
import DefaultLayout from '../layouts/DefaultLayout.vue'
// import Dashboard from '@/pages/dashboard/Home.vue'
import Error403 from '../pages/error/403.vue'
import Error404 from '../pages/error/NotFound.vue'
const ar = localStorage.getItem("lang") == "ar"
const routes = [
  // English Login Routes
  {
    path: '/',
    component: ar ? LoginAr : Login,
    beforeEnter: checkUserSession,
    redirect: ar ? "ar-adminLogin" : "en-adminLogin",
    children: [
      {
        path: ar ? "ar-adminLogin" : "en-adminLogin",
        component: ar ? LoginAr : Login,
      }
    ],
  },
  {
    path: '/en-adminLogin',
    beforeEnter: checkUserSession,
    name: 'en-adminLogin',
    component: Login,
  },
  // Arabic Login Routes
  {
    path: '/ar-adminLogin',
    beforeEnter: checkUserSession,
    name: 'ar-adminLogin',
    component: LoginAr,
  },
  {
    path: '/',
    redirect: '/dashboard',
    beforeEnter: authenticateUser,
    component: DefaultLayout,
    children: [
      {
        path: 'dashboard',
        alias: '',
        name: 'dashboard',
        // meta: {
        //   screen: "view_overview_dashboard",
        // },
        component: DashboardComponent
      },
    ]
  },
  {
    path: '/access-forbidden',
    name: 'access-forbidden',
    component: Error403,
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error404,
  },
]

export default routes