<template>
  <div 
    class="px-5 flex items-center py-3 mt-3 cursor-pointer rounded-lg bg-white"
    :style="{ border: '1px solid' + activeColor}"
    :class="[activeMenu ? 'rounded-b-none' : 'group']"
    @click="onClick">
      <img :src="icon" class="w-6 h-6" /> 
    <div class="pl-4 flex-grow flex items-center justify-between">
      <div 
        :style="{ color: activeColor}"
        class="text text-sm font-medium"
        >
        {{ text }}
      </div>
        <div v-if="subMenuExists && !showSubMenu"
          class="p-1 rounded" :style="{ background: activeBgColor}">
        <svg 
        :style="{ color: activeColor}"
        class="w-4 h-4 transform transition duration-100"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
</svg>
        </div>
  

<div v-if="subMenuExists && showSubMenu"
         class="p-1 rounded" :style="{ background: activeBgColor}">
         <svg v-if="subMenuExists && showSubMenu"
        :style="{ color: activeColor}"
        class="w-4 h-4 transform transition duration-100"
         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75" />
</svg>
        </div>




    </div>
  </div>
  <div 
    v-if="subMenuExists" 
    class="h-0 overflow-hidden rounded-b-lg bg-white"
    :class="[{'h-auto': showSubMenu, 'border-none': !showSubMenu}]"
    :style="[showSubMenu ? {'border': '1px solid' + activeColor } : {'border': 'none'}]"
    >
    <slot name="sub-menu" />
  </div>
  <div 
    v-if="subMenuExists" 
    class="h-0 overflow-hidden"
    :class="{'h-auto': showSubMenu}">
    <slot name="sub-sub" />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'TheSidebarMenuItem',
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    activeColor: {
      type: String,
      required: true
    },
    activeBgColor: {
      type: String,
      required: true
    },
    iconClass: {
      type: [String, Array, Object],
      default: ''
    },
    route: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean
    }
  },
  emits: ['update:active'],
  setup(props, {emit, slots}) {
    const subMenuExists = computed(() => !!slots['sub-menu'])
    const showSubMenu = ref(false)
    const activeMenu = ref(false)

    watch(() => props.active, (val) => {
      activeMenu.value = val
      if (subMenuExists) showSubMenu.value = val
    }, { immediate: true })

    watch(activeMenu, (val) => emit('update:active', val))

    const route = useRoute()
    watch(route, (val) => {
      if (val.name == props.route?.name || val.path == props.route?.path) {
        activeMenu.value = true
      } else {
        activeMenu.value = false
      }
    }, { immediate: true })

    const router = useRouter()
    const onClick = () => {
      if (subMenuExists.value){
        showSubMenu.value = !showSubMenu.value
        activeMenu.value = !activeMenu.value
      }
       
      else if (props.route) router.push(props.route)
      else console.log('No action found!')
    }
    
    const setActiveMenu = (val) => {
      activeMenu.value = val
    }

    return {
      subMenuExists,
      activeMenu,
      showSubMenu,
      onClick,
      setActiveMenu
    }
  }
}
</script>